<template>
  <div class="topContainer">
    <div class="wrap-checkout">
      <img src="/img/bck/Navicella.svg" class="img-fluid space"/>
      <loading :active.sync="isLoading"></loading>
      <div class="container " v-if="!isLoading && cart && features">
        <div class="card bg-white shadow cardCheckout">
          <div class="row">
            <div class="col-sm-12 col-md-5 imgProducts">
              <img src="/img/00gate.jpg" class="img-fluid img-responsive"/>
              <img src="/img/madeinitaly.jpg" class="img-fluid img-responsive"/>
            </div>
            <div class="col-sm-12 col-md-7 p-5 vpsData">
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right">vCPU </span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold">{{features.CORE.value}}   </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right">GB vRAM</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold">{{features.RAM.value}} </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right">GB vSTO NVMe</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold"> {{features.STONVME.value}} </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right"> IPv4</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold"> {{features.IPV4.value}}</span>
                </b-col>
              </b-row>
              <b-row v-for="row in cart.rows" v-if="row.quantity > 0">
                <b-col sm="6">
                  <span class="align-right text-right" v-if="row.type === 'ipv4' ">IPv4 {{$t('message.further')}}</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold" v-if="row.type === 'ipv4' ">{{ row.quantity }}  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right">{{features.TRAFF.key}}</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold">{{features.TRAFF.value}} </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <span class="align-right text-right"> {{$t('message.bandwidth')}}</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold">{{features.BW.value}}</span>
                </b-col>
              </b-row>
              <b-row v-if="cart.totals.discount !== '0'">
                <b-col sm="6">
                  <span class="align-right text-right"> {{$t('message.discount')}}</span>
                </b-col>
                <b-col sm="6">
                  <span class="txtbold"> {{cart.totals.discount}} %</span>
                </b-col>
              </b-row>
              <b-row v-if="cart.totals.discount != '0'">
                <b-col sm="6">
                  <span v-if="cart.totals.month === '1'"> {{$t('message.fee')}}</span>
                  <span v-else> {{$t('message.fee')}} </span>
                </b-col>
                <b-col sm="6" class="mb-1">
                  <span class="txtbold">
                    <span v-if="cart.totals.month === '1'"> {{$t('message.montly')}}</span>
                    <span v-else>  {{$t('message.every')}} {{cart.totals.month}} {{$t('message.months')}} </span>
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <validation-field
                    :label="$t('message.select_hostname')"
                    :v="$v.form.host"
                  >
                    <input name="host" type="text" class="form-control" id="host" autocomplete="off" v-model="form.host"
                           v-on:keydown="$v.form.host.$touch()">
                  </validation-field>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <validation-field :label="$t('message.password')" :v="$v.form.pwd">
                    <input class="form-control" id="pwd"
                           maxlength="50"
                           type="password"
                           v-model="form.pwd"
                           autocomplete="false"
                           v-on:keydown="$v.form.pwd.$touch()"/>
                  </validation-field>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <validation-field :label="$t('message.label_confirm_password')" :v="$v.form.pwd_confirm">
                    <input class="form-control" id="pwd_confirm"
                           maxlength="50"
                           type="password"
                           v-model="form.pwd_confirm"
                           autocomplete="off"
                           v-on:blur="$v.form.pwd_confirm.$touch()"/>
                  </validation-field>
                </b-col>
              </b-row>
              <b-row :class="{invalid: $v.form.tos.$invalid}">
                <b-col sm="12">
                  <validation-field
                    :v="$v.form.tos" :isCheckbox="true">
                    <input class="form-check-input" type="checkbox"
                           v-model="form.tos"
                           v-on:blur="$v.form.tos.$touch()"/>
                    <label class="form-check-label">
                      <router-link :to="{name:'tos'}" target="_blank">{{$t('message.label_checkout_tos')}}</router-link>
                    </label>
                  </validation-field>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <validation-field
                    :v="$v.form.tos2" :is-checkbox="true">
                    <input class="form-check-input" type="checkbox" v-model="form.tos2"
                           v-on:blur="$v.form.tos2.$touch()">
                    <label class="form-check-label">
                      <router-link :to="{name:'tos2'}" target="_blank">{{$t('message.label_checkout_tos_clauses')}}</router-link>
                    </label>
                  </validation-field>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <validation-field
                    :v="$v.form.sla" :is-checkbox="true">
                    <input class="form-check-input" type="checkbox" v-model="form.sla"
                           v-on:blur="$v.form.sla.$touch()">
                    <label class="form-check-label">
                      <router-link :to="{name:'sla'}" target="_blank">{{$t('page.sla_title')}}</router-link>
                    </label>
                  </validation-field>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-12">
            <b-alert show block class="bg-theme-secondary text-center">
                <span style="font-size: 22px">
                  {{$t('message.total')}} {{cart.amount | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'})}}
                </span> &nbsp;
              <small>{{$t('message.plus_vat')}}</small>
              <span style="color: #ffffff; font-size: 16px" v-if="cart.totals.discount != '0'"> {{$t('message.instead_of')}} {{parseFloat(cart.amount) + parseFloat(cart.totals.discountTotal) | currency('&euro;', 2, { spaceBetweenAmountAndSymbol: true,decimalSeparator: ',' ,thousandsSeparator: '.'}) }} </span>
            </b-alert>
          </div>





          <b-row v-if="currentUser.anonymous">
            <b-col md="12" sm="12" xl="12">
              <router-link
                :to="{name : 'login'}"
                class="txtwhite">
                <b-button block variant="success" :class="{invalid:$v.form.$invalid}">
                  {{$t('message.login_or_register_text')}}
                </b-button>
              </router-link>
            </b-col>
          </b-row>

          <b-row v-if="currentUser.pending">
            <b-col md="12" sm="12" xl="12">
              <router-link
                :to="{name : 'sendRegistrationtoken'}"
                class="txtwhite">
                <b-button block class="bg-theme-secondary">
                  {{$t('message.confirm_email_pending')}}
                </b-button>
              </router-link>
            </b-col>
          </b-row>

          <b-row v-if="currentUser.registered">
            <b-col md="12" sm="12" xl="12">
              <router-link
                :to="{name : 'register'}"
                class="txtwhite"
              >
                <b-button block class="bg-theme-secondary">
                  {{$t('message.complete_registration')}}
                </b-button>
              </router-link>
            </b-col>
          </b-row>
          <div class="col-12">
            <b-row v-if="currentUser.customer && !this.$v.form.$invalid">
              <b-col md="4" sm="12" xl="4">
                <b-button block id="goSubscription" :disabled="cart.totals.month > 12 " v-on:click="createSubscription"
                          class="bg-theme-primary mb-2" :class="{invalid:$v.form.$invalid}">
                  {{$t('message.pay_subscription_text')}}
                </b-button>
              </b-col>
              <b-col md="4" sm="12" xl="4">
                <b-button block id="goExpress" v-on:click="createExpressCheckout" class="bg-theme-primary mb-2"
                          :class="{invalid:$v.form.$invalid}">
                  {{$t('message.pay_manual')}}
                </b-button>
              </b-col>

              <b-col md="4" sm="12" xl="4" v-if="cart.trial === true && currentUser.hasUsedDemo === false">
                <b-button block id="goTrial" v-on:click="createTrialCheckout"
                          class="bg-theme-primary mb-2">
                  {{$t('message.pay_trial')}}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiResolver from '../components/common/ApiResolver';
  import Api from '../components/common/Api';
  import ValidationField from "../components/ui/ValidationField";
  import Auth from "../components/auth/Auth";
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import I18n from "../components/i18n/I18n";
  import {mapState, mapGetters} from "vuex";
  import {email, helpers, required, sameAs, minLength, maxLength, minValue} from 'vuelidate/lib/validators';
  import DisableAutocomplete from 'vue-disable-autocomplete';

  const atLeastOneUppercaseLetter = helpers.regex('alpha', /[A-Z]/);
  const atLeastOneLowercaseLetter = helpers.regex('alpha', /[a-z]/);
  const atLeastOneNumber = helpers.regex('alpha', /[0-9]/);
  const atLeastOneSymbol = helpers.regex('alpha', /(?=.*?[#?!$%&*_])/);
  const notSuchSymbols = helpers.regex('alpha', /^[a-zA-Z0-9#?!$%&*_]*$/);
  //const isHostname = helpers.regex('alpha',/^([A-Za-z0-9]*)$/)
  //const isHostname = helpers.regex('alpha', /^([a-z][a-z0-9\-]{2,13}[a-z0-9])$/)
  const isHostname = helpers.regex('alpha', /^([a-z][a-z0-9]{2,13}[a-z0-9])$/)

  export default {
    components: {
      ValidationField,
      Loading,
      DisableAutocomplete
    },
    props: {},
    data() {
      return {
        isLoading: false,
        isTrial: false,
        features: {},
        currentVps: null,
        form: {
          host: null,
          pwd: null,
          pw_confirm: null,
          id: null,
          sla: null,
          tos: null,
          tos2: null,
          sentAt : null
        },
      };
    },
    computed: {
      ...mapState({
        cart: state => state.cart,
        currentUser: state => state.user.currentUser
      }),
    },
    validations: {
      form: {
        host: {
          required: required,
          isHostname,
          max: maxLength(15),
          min: minLength(4)
        },
        pwd: {
          required: required,
          atLeastOneUppercaseLetter,
          atLeastOneLowercaseLetter,
          atLeastOneNumber,
          min: minLength(8),
          max: maxLength(20),
          atLeastOneSymbol,
          notSuchSymbols
        },
        pwd_confirm: {
          required: required,
          sameAs: sameAs('pwd')
        },
        id: {},
        tos: {
          required: required,
          sameAs: sameAs(() => true)
        },
        tos2: {
          required: required,
          sameAs: sameAs(() => true)
        },
        sla: {
          required: required,
          sameAs: sameAs(() => true)
        }
      },
    },
    methods: {
      getCurrentDatetime() {
        return this.$moment().format();
      },
      getCartProperties(cartNumber) {
        Api
          .get(ApiResolver.get('CART_PROPERTIES') + '?cartNumber=' + cartNumber)
          .then(response => {
            if (response.data.length) {
              this.form.host = response.data[0].hostname;
              this.form.id = response.data[0].id;
            } else {
              Api
                .post(ApiResolver.get('CART_PROPERTIES'), JSON.stringify({
                  cartNumber: cartNumber
                }))
                .then(response => {
                  this.form.host = response.data.host;
                  this.form.id = response.data.id;
                })
                .catch(reason => {
                });
            }
          })
          .catch(reason => {
          });
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        Api
          .put(ApiResolver.get('CART_PROPERTIES') + '/' + this.form.id, JSON.stringify({
            cartNumber: this.cart.number,
            hostname: this.form.host,
            password: this.form.pwd,
            sla: this.form.sla,
            tos: this.form.tos,
            tos2: this.form.tos2,
          }))
          .then(response => {
            if (response.data) {
              this.isLoading = false;
              this.$router.push({name: 'checkout'})
            }
          })
          .catch(reason => {
            this.isLoading = false;
          });
      },
      mapItems: function (item) {
        switch (item) {
          case 'cp' :
            return this.$t('message.control_panel')
          case 'os' :
            return this.$t('message.operating_system')
          case 'vps' :
            return this.$t('message.vps_text')
          case 'ipv4' :
            return this.$t('message.ipv4')
        }
      },
      createSubscription: function () {
        this.isLoading = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return
        Api
          .put(ApiResolver.get('CART_PROPERTIES') + '/' + this.form.id, JSON.stringify({
            cartNumber: this.cart.number,
            hostname: this.form.host,
            password: this.form.pwd,
            sla: this.form.sla,
            tos: this.form.tos,
            tos2: this.form.tos2,
            sentAt : this.getCurrentDatetime()
          }))
          .then(response => {
            if (response.data) {
              Api.post(ApiResolver.get('SUBSCRIPTION'), JSON.stringify({
                serialNumber: this.cart.number,
                customerNumber: this.currentUser.customerNo,
                months: this.cart.totals.month,
                amount: this.cart.amountIncludingVat,
                urlReturnSuccess: process.env.VUE_APP_FRONTEND_URL + '/' + I18n.getCurrentLang() + '/payment-success?type=Recurrent&cartNumber=' + this.cart.number,
                urlReturnError: process.env.VUE_APP_FRONTEND_URL + '/' + I18n.getCurrentLang() + '/payment-cancel?type=Recurrent&cartNumber=' + this.cart.number,
              }))
                .then((response) => {
                  window.location.href = response.data.link.href;
                })
                .catch((error) => {
                  this.isLoading = false;
                  // handle error
                  //console.log(response.data);
                });
            }
          })
          .catch(reason => {
            this.isLoading = false;
          });
      },
      createExpressCheckout: function () {
        this.isLoading = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return
        Api
          .put(ApiResolver.get('CART_PROPERTIES') + '/' + this.form.id, JSON.stringify({
            cartNumber: this.cart.number,
            hostname: this.form.host,
            password: this.form.pwd,
            sla: this.form.sla,
            tos: this.form.tos,
            tos2: this.form.tos2,
            sentAt : this.getCurrentDatetime()
          }))
          .then(response => {
            if (response.data) {
              Api.post(ApiResolver.get('EXPRESS_PAYMENT'), JSON.stringify({
                serialNumber: this.cart.number,
                customerNumber: this.currentUser.customerNo,
                months: this.cart.totals.month,
                amount: this.cart.amountIncludingVat,
                urlReturnSuccess: process.env.VUE_APP_FRONTEND_URL + '/' + I18n.getCurrentLang() + '/payment-success?type=Single&cartNumber=' + this.cart.number,
                urlReturnError: process.env.VUE_APP_FRONTEND_URL + '/' + I18n.getCurrentLang() + '/payment-cancel?type=Single&cartNumber=' + this.cart.number,
              }))
                .then((response) => {
                  //this.isLoading = false;
                  window.location.href = response.data.link.href;
                })
                .catch((error) => {
                  // handle error
                  this.isLoading = false;
                  //console.log(error);
                });
            }
          })
          .catch(reason => {
            this.isLoading = false;
          });
      },
      createTrialCheckout: function () {
        this.isLoading = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return
        Api
          .put(ApiResolver.get('CART_PROPERTIES') + '/' + this.form.id, JSON.stringify({
            cartNumber: this.cart.number,
            hostname: this.form.host,
            password: this.form.pwd,
            sla: this.form.sla,
            tos: this.form.tos,
            tos2: this.form.tos2,
            sentAt : this.getCurrentDatetime()
          }))
          .then(response => {
            if (response.data) {
              Api.post(ApiResolver.get('TRIAL_CHECKOUT'), JSON.stringify({
                serialNumber: this.cart.number,
                customerNumber: this.currentUser.customerNo,
              }))
                .then((response) => {
                  //this.isLoading = false;
                  this.$store.dispatch('alerts/setAlert', {
                    type: 'success',
                    text: this.$t('message.trial_successfull')
                  });
                  this.$router.push({
                    name: 'demoSuccess', params: {
                      lang: this.$store.state.user.lang
                    }
                  });
                })
                .catch((error) => {
                  // handle error
                  this.isLoading = false;
                  //console.log(error);
                });
            }
          })
          .catch(reason => {
            this.isLoading = false;
          });
      },
    },
    async mounted() {
      //console.log(this.$moment());
      await this.$store.dispatch('user/current', {});
      let vps = this.cart.rows[0].code;
      this.getCartProperties(this.cart.number);
      /* recupero le informazioni su hostname dal carrello se ci sono */
      //this.getCartProperties(this.cart.number);
      this.isLoading = true;
      Api.get(ApiResolver.get('PRODUCTS') + '&Item_Code=' + vps)
        .then((response) => {
          //console.log(response.data[vps].features)
          this.currentVps = vps;
          this.features = response.data[vps].features;
          this.isLoading = false;
        })
        .catch(function (error) {
          reject(false);
        });
    },
  };
</script>

<style lang="scss" src="spinkit/scss/spinkit.scss"/>

<style scoped>
  .hasError label {
    color: red;
  }
  .error {
    color: red
  }
  .sk-fading-circle .sk-circle {
    margin: 0 auto;
  }
  .sk-three-bounce {
    height: 40px;
  }
</style>
<style type="text/css">
  ul {
    list-style-type: none;
  }
  li {
    margin-top: 5px;
  }

  .wrap-checkout {
    background-size:  cover;
    background-repeat: no-repeat;
    background-position: top;
    margin-bottom: 0 !important;
    min-height: 880px;
  }

  @media only screen
  and (min-device-width : 320px)
  and (max-device-width : 480px) {
    .wrap-checkout {
      background: none !important;
    }
    .topContainer {
      margin-top: 25px;
    }
     img.space {
      display: none !important;
    }
    .cardCheckout {
      box-shadow: none !important;
      border: 0 !important;
    }
    .imgProducts {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .imgProducts img{
      max-width: 150px !important;
    }
    .vpsData {
      text-align: center !important;
    }
  }

</style>


